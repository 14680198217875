import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useUsers(
  searchOptions: MaybeRef<{
    page: number
    itemsPerPage: number
    searchQuery?: string
    onlyOwn?: boolean
  }>,
) {
  return useQuery({
    queryKey: ['users', searchOptions] as const,
    queryFn: ({ queryKey }) => useTRPC().user.list.query(queryKey[1]),
    staleTime: 1000 * 60,
    placeholderData: (prev) => prev,
  })
}

export function useUsersMutations() {
  const toast = useToast()
  const queryClient = useQueryClient()

  const updateUser = useMutation({
    mutationFn: useTRPC().user.update.mutate,
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      toast.add({ title: 'User aktualisiert' })
    },
    onError: () => toast.add({ title: 'User konnte nicht aktualisiert werden', color: 'red' }),
  })

  return {
    updateUser,
  }
}
